<script>
  import { mdiMagnify } from "@mdi/js";
  import { defineComponent, onMounted, ref } from "@vue/composition-api";

  import { useEstimateItemsByConstructionRecordId } from "@/composables/useEstimateItems";
  import { store } from "@/store";

  export default defineComponent({
    setup() {
      const { estimateItems, estimateItemsHaveLoaded } =
        useEstimateItemsByConstructionRecordId();

      const headers = [
        { text: "ITEM NUMBER", value: "item_num", sortable: true },
        { text: "DESCRIPTION", value: "item_desc", sortable: false },
        { text: "NEGOTIABLE", value: "negotiable", sortable: true }
      ];

      const search = ref(null);

      onMounted(() => {
        store.dispatch("estimate-items/updatePageLimit", 1000);
      });
      return {
        estimateItems,
        estimateItemsHaveLoaded,
        icons: {
          mdiMagnify
        },
        headers,
        search
      };
    }
  });
</script>
<template>
  <v-card>
    <v-card-title>
      Short List Items for this Permit
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        outlined
        dense
        hide-details
        :prepend-inner-icon="icons.mdiMagnify"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="estimateItems"
      :search="search"
      dense
    ></v-data-table>
  </v-card>
</template>
